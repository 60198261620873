const { setCompany, setAppId } = require('../utils')

module.exports = [
    {
        name: 'company_home',
        path: `/company/:company_id`,
        component: () => import('../views/Home.vue'),
        beforeEnter: (to, from, next) => {
            setCompany(to.params.company_id)
            next()
        }
    },
    {
        name: 'application_home',
        path: `/company/:company_id/application/:application_id`,
        component: () => import('../views/Main.vue'),
        beforeEnter: (to, from, next) => {
            setCompany(to.params.company_id)
            setAppId(to.params.application_id)
            next()
        }
    },
    {
        name: 'settings',
        path: `/company/:company_id/application/:application_id/settings`,
        component: () => import('../views/Settings.vue'),
        beforeEnter: (to, from, next) => {
            setCompany(to.params.company_id)
            setAppId(to.params.application_id)
            next()
        }
    },
]
