<template>
  <div id="app">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'App',

  mounted() {
    import(
      /*webpackChunkName:"nitrozen-snackbar" */ '@gofynd/nitrozen-vue/src/components/NSnackbar'
    ).then((NitrozenSnackbar) => {
      Vue.use(NitrozenSnackbar.default)
      Vue.snackbar.register('show', (message) => message, {
        position: 'top-center',
        duration: 3000
      })
      Vue.snackbar.register('showSuccess', (message) => message, {
        position: 'top-center',
        duration: 3000,
        type: 'success'
      })
      Vue.snackbar.register('showError', (message) => message, {
        position: 'top-center',
        duration: 3000,
        type: 'error'
      })
      Vue.snackbar.register('showWarning', (message) => message, {
        position: 'top-center',
        duration: 3000,
        type: 'warning'
      })
      Vue.snackbar.register('showInfo', (message) => message, {
        position: 'top-center',
        duration: 3000,
        type: 'info'
      })
    })
  }
}
</script>

<style>
@import '../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css';

html,
body {
  margin: 0px !important;
  padding: 0px !important;
  height: 100%;
}

#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  background-color: #f8f8f8;
  color: #2c3e50;
  padding: 24px;
  height: inherit;
  box-sizing: border-box;
}
</style>
