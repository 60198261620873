// import { createStore } from "vuex";

/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default function createStore() {
  return new Vuex.Store({
    state: {
      sales_channels: [],
      application_details: {},
      application_dynamic_yield_config: {}
    },
    getters: {
      getSalesChannels: (state) => state.sales_channels,
      getApplicationDetails: (state) => state.application_details,
      getApplicationDynamicYieldConfig: (state) => state.application_dynamic_yield_config
    },
    actions: {

    },
    mutations: {
      SET_SALES_CHANNELS(state, salesChannelData) {
        state.sales_channels = salesChannelData
      },
      SET_APP_DETAILS(state, appDetail) {
        state.application_details = appDetail
      },
      SET_APP_DYNAMIC_YIELD_CONFIG(state, appDynamicYieldConfig) {
        state.application_dynamic_yield_config = appDynamicYieldConfig
      }
    },
    modules: {}
  })
}
