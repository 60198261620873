import Vue from 'vue'
import Router from 'vue-router'
import extensionRoutes from './extension'

Vue.use(Router)


const routes = [
  ...extensionRoutes,
]

export default function createRouter() {
  return new Router({
    mode: 'history',
    routes
  })
}
